.replacement-suggestions-container[data-v-ce9fb5ed] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  align-self: start;
  gap: 1rem;
}
.replacement-suggestions[data-v-ce9fb5ed] {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  background-color: var(--content-bg--color-lighter);
  border-radius: var(--radius);
  border: 1px solid var(--color-border-primary);
  box-shadow: 0px 0px 4px 0px var(--color-shadow-primary);
  padding: 2rem 1rem;
}
.week-replacement-suggestions[data-v-ce9fb5ed] {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  width: 45rem;
  padding: 1rem 0;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.week[data-v-ce9fb5ed] {
  width: 100%;
  height: 100%;
}
.week-day-name[data-v-ce9fb5ed] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  text-align: center;
  width: 100%;
}
.week-day-cars[data-v-ce9fb5ed] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
}
.week-day-car[data-v-ce9fb5ed] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-border-primary);
  padding-inline: 6px;
  height: 1.6rem;
  gap: 0.2rem;
  border-radius: var(--radius);
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}
.week-day-car.-replacement[data-v-ce9fb5ed] {
  height: 9.8rem;
}
.week-day-car.-no-hover[data-v-ce9fb5ed]:hover {
  cursor: auto;
  background-color: transparent;
}
.car-details[data-v-ce9fb5ed] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.week-day-empty[data-v-ce9fb5ed] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.week-replacements-suggestions-buttons[data-v-ce9fb5ed] {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1rem;
}
.replacement[data-v-ce9fb5ed] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.4rem;
  border: 1px dashed var(--color-border-primary);
  border-radius: var(--radius);
  width: 100%;
  transition: background-color var(--p-transition-duration) ease-in-out;
}
.replacement[data-v-ce9fb5ed]:hover {
  background-color: var(--content-bg--color-focus);
}
.replacement[data-v-ce9fb5ed]:has(.delete-replacement-button:hover) {
  background-color: transparent;
}
.replacement-details[data-v-ce9fb5ed] {
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
  padding-top: 0.2rem;
}
.replacement-car[data-v-ce9fb5ed] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  width: 100%;
  margin-top: -0.4rem;
}
.replacement-car-text[data-v-ce9fb5ed] {
  max-width: 10rem;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: block;
  text-align: left;
}
.replacement-dates[data-v-ce9fb5ed] {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
}
.replacement-date[data-v-ce9fb5ed] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-submit);
}
.delete-replacement-container[data-v-ce9fb5ed] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.delete-replacement-button[data-v-ce9fb5ed] {
  color: var(--color-icon);
}
.delete-replacement-button[data-v-ce9fb5ed]:hover {
  color: var(--color-text-warning);
}