.registration-car[data-v-5a783016] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  align-self: start;
  gap: 1rem;
}
.pdf-icon[data-v-5a783016] {
  width: 6rem;
  height: 6rem;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.move-right-icon[data-v-5a783016] {
  color: var(--color-button-bg-submit);
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.car-incharge-form-block[data-v-5a783016] {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
}
.car-incharge-form-block.-container[data-v-5a783016] {
  max-width: 28rem;
  background-color: var(--content-bg--color-lighter);
  border-radius: var(--radius);
  border: 1px solid var(--color-border-primary);
  box-shadow: 0px 0px 4px 0px var(--color-shadow-primary);
  padding: 2rem 1rem;
  opacity: 0;
  transition: opacity 0.6s ease-in;
}
.car-incharge-form-block.-row[data-v-5a783016] {
  flex-direction: row;
  justify-content: space-between;
}
.car-incharge-form-block.-no-padding[data-v-5a783016] {
  padding: 0;
}
.car-form-subtitle[data-v-5a783016] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.4rem;
}
.fade-in[data-v-5a783016] {
  opacity: 1 !important;
  transform: translateX(0) !important;
}