.replacement-car-button[data-v-e47007f4] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 0;
  padding-block: 0;
}
.replacement-car-button[data-v-e47007f4]:hover {
  background-color: transparent;
  transform: scale(1.1);
}
.replacement-speed-dial-item-button[data-v-e47007f4] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  padding: 0.2rem;
  border-radius: 50%;
  color: var(--color-text-secondary);
  background-color: var(--content-bg--floating-button);
}
.replacement-speed-dial-item-button[data-v-e47007f4]:hover {
  color: var(--color-text-primary);
}
.replacement-speed-dial-item-button.-danger[data-v-e47007f4]:hover {
  color: var(--color-text-warning);
}
.replacement-car-icon[data-v-e47007f4] {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-text-submit);
}
.replacement-car-icon.-finished[data-v-e47007f4] {
  color: var(--color-text-success);
}
.replacement-car-icon.-affected[data-v-e47007f4] {
  color: var(--color-text-feedback);
}