.ai-features[data-v-1721d3b4] {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  gap: 1rem;
}
.indicator-list[data-v-1721d3b4] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.indicator-list-header[data-v-1721d3b4] {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: var(--color-text-primary);
  text-align: left;
  font-size: 2rem;
  line-height: 2.1rem;
}
.indicator-item[data-v-1721d3b4] {
  display: flex;
  gap: 1rem;
  padding: 0.4rem 0;
}
.indicator-item[data-v-1721d3b4]:hover {
  background-color: transparent;
}
.indicator-item-slot[data-v-1721d3b4] {
  width: 0.3rem;
  height: 2.5rem;
  border-radius: 6px;
  background-color: var(--content-bg--color-lighter);
}
.indicator-item-label[data-v-1721d3b4] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  text-align: left;
}
.indicator-item-label[data-v-1721d3b4]:hover {
  color: var(--color-text-primary);
}
.indicator-item:not(.active):hover .indicator-item-slot[data-v-1721d3b4] {
  background-color: var(--content-bg--color-contrast);
}
.indicator-item.active .indicator-item-slot[data-v-1721d3b4] {
  background-color: var(--p-form-field-focus);
}
.indicator-item.active .indicator-item-slot[data-v-1721d3b4]:hover {
  background-color: var(--p-form-field-focus);
}
.indicator-item.active .indicator-item-label[data-v-1721d3b4] {
  color: var(--color-text-primary);
}
.items-container[data-v-1721d3b4] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
  padding: 1rem;
}
.fade-slide-enter-active[data-v-1721d3b4], .fade-slide-leave-active[data-v-1721d3b4] {
  transition: opacity var(--p-transition-duration) ease, transform var(--p-transition-duration) ease;
}
.fade-slide-enter-from[data-v-1721d3b4] {
  opacity: 0;
  transform: translateY(20px);
}
.fade-slide-leave-to[data-v-1721d3b4] {
  opacity: 0;
  transform: translateY(-20px);
}